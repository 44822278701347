module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"https://contentapi.kuzmin.com.au/graphql.","url":"https://contentapi.kuzmin.com.au/graphql.","protocol":"https","hostingWPCOM":false,"useACF":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    }]
